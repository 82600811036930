export const palette = {
  black: {
    4: "rgba(0, 0, 0, 0.04)",
    12: "rgba(0, 0, 0, 0.12)",
    38: "rgba(0, 0, 0, 0.38)",
    56: "rgba(0, 0, 0, 0.56)",
    87: "rgba(0, 0, 0, 0.87)",
  },
  white: {
    56: "rgba(255, 255, 255, 0.56)",
    87: "rgba(255, 255, 255, 0.87)",
    100: "#fff",
  },
  blue: {
    default: "#7fa2f5",
    dark1: "#5d8af5",
    dark2: "#4075f3",
    light1: "#adc2f6",
    light2: "#e6ebf8",
  },
  turquoise: {
    default: "#6ed8d9",
    dark1: "#43cccd",
    dark2: "#22bfc0",
    light1: "#9cebec",
    light2: "#ecfcfc",
  },
  red: {
    default: "#ea4f4f",
    dark1: "#e63e3e",
    dark2: "#d72f2f",
    light1: "#f4a1a1",
    light2: "#ffeaea",
  },
  extra: {
    backgroundColor: {
      control: "#eceff5",
    },
  },
};
