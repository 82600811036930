export const duration = {
  default: "0.25s",
};

export const fontFamily = {
  default:
    '"Noto Sans CJK JP", "Helvetica Neue", arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", meiryo, sans-serif',
};

export const fontSize = {
  xs: "12px",
  sm: "14px",
  md: "16px",
  lg: "18px",
  xl: "20px",
};

export const radius = {
  sx: "2px",
  sm: "4px",
  md: "8px",
  lg: "12px",
  xl: "16px",
  circle: "9999em",
};

export const spacing = {
  xxs: "4px",
  xs: "8px",
  sm: "12px",
  md: "16px",
  lg: "20px",
  xl: "24px",
  xxl: "32px",
};
